import React from "react";

import Header from '../components/header';
import Footer from '../components/footer';
import Meta from '../components/meta';

export default () => 
  <div className="vh-100">
    <Meta title="FAQs" />
    <Header darkFont={true}></Header>
    <div className="p-5 off-white-background container-fluid">
      <div className="row justify-content-center py-5 mt-5">
        <div className="col-md-8 col-lg-6 col-xl-5 px-0 text-center mt-5">
          <h1 className="text-dark serif d-inline-block">FAQs</h1>
          <hr className="col-1 horizontal-divider mb-5"></hr>

          <h3 className="serif text-dark font-weight-bold">Is jade as hard as marble or granite?</h3>
          <p className="text-dark sans-serif mt-4">
            Nephrite has a Mohs hardness of 6-7, roughly equivalent to that of quartz and granite.
          </p>
          <hr className="col-1 horizontal-divider my-5"></hr>

          <h3 className="serif text-dark font-weight-bold">Where does the jade come from?</h3>
          <p className="text-dark sans-serif mt-4">
            Our jade is quarried reclaimed in Northern British Columbia, Canada.
          </p>
          <hr className="col-1 horizontal-divider my-5"></hr>

          <h3 className="serif text-dark font-weight-bold">Will jade stain or scratch?</h3>
          <p className="text-dark sans-serif mt-4">
            Jade is the toughest stone available for architectural and design applications. Its composition of
            interlocking crystals allows makes it resistant to blows and scratches, and allows the stone to be cut
            thin, span long distances, and even flex.
          </p>
          <hr className="col-1 horizontal-divider my-5"></hr>

          <h3 className="serif text-dark font-weight-bold">Isn’t jade very expensive?</h3>
          <p className="text-dark sans-serif mt-4">
            Jade can be one of the most expensive gemstones in the world. But these higher prices are only
            attributed to a small percentage of the jade mined today. We focus on the stone that can yield larger,
            more economically viable pieces.
          </p>
          <hr className="col-1 horizontal-divider my-5"></hr>

          <h3 className="serif text-dark font-weight-bold">Why Vancouver Jade?</h3>
          <p className="text-dark sans-serif mt-4">
            Vancouver is the global hub for jade. 85% of the world’s nephrite jade leaves Vancouver every year. We
            also love this place and want the world to know more about our beautiful home.
          </p>
          <hr className="col-1 horizontal-divider my-5"></hr>

          <h3 className="serif text-dark font-weight-bold">How rare is jade?</h3>
          <p className="text-dark sans-serif mt-4">
            Jade is very rare and only exists in a few spots in the world. Much of it is in hard to reach places that are
            accessible for only a few months each year do to the harsh Canadian winters.
          </p>
          <hr className="col-1 horizontal-divider my-5"></hr>

          <h3 className="serif text-dark font-weight-bold">Do you stock slabs?</h3>
          <p className="text-dark sans-serif mt-4">
            We choose to stock very little material. The rarity of jade means most projects are made to order. We
            do, however, stock boulders and blocks.
          </p>
          <hr className="col-1 horizontal-divider my-5"></hr>

          <h3 className="serif text-dark font-weight-bold">Is all jade green?</h3>
          <p className="text-dark sans-serif mt-4">
            Not necessarily. BC nephrite jade is prized for its deep greens, but jade can be found in a variety of
            colours from light-blue to near-black, with endless variations of patterns caused by trace elements and
            inclusions.
          </p>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
